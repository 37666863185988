import { BaseCosmicObject, ResponsiveCosmicImage } from './UIModules';

export enum PaymentMethod {
  SEPA = 'stripe_debit_sepa',
  CREDIT_CARD = 'stripe_credit_card',
  ACH = 'stripe_debit_ach',
  PAYPAL = 'paypal',
  KLARNA = 'klarna',
  GOOGLE_PAY = 'google_pay',
  APPLE_PAY = 'apple_pay',
  LINK = 'link',
  JOBAUTO = 'jobauto',
}

export enum StripePaymentMethod {
  SEPA = 'sepa_debit',
  CREDIT_CARD = 'card',
  PAYPAL = 'paypal',
}

export type PaymentMethodCosmicData = {
  title: string;
  type: PaymentMethod | string;
  description?: string;
  info_box?: string;
  payment_providers_images: ResponsiveCosmicImage[];
};

export type PaymentMethodData = BaseCosmicObject<PaymentMethodCosmicData>;

export enum PaymentInputValuesAsStatus {
  SUCCESS = 'success',
  INVALID = 'invalid',
}

export enum StripeErrorList {
  INCORRECT_CVC = 'incorrect_cvc',
  CARD_DECLINED = 'card_declined',
  LIVE_MODE_TEST_CARD = 'live_mode_test_card',
  EXPIRED_CARD = 'expired_card',
  INCORRECT_NUMBER = 'incorrect_number',
  PROCESSING_ERROR = 'processing_error',
  SETUP_INTENT_AUTHENTICATION_FAILURE = 'setup_intent_authentication_failure',
  PAYMENT_INTENT_AUTHENTICATION_FAILURE = 'payment_intent_authentication_failure',
  PAYMENT_METHOD_NOT_EXIST_ON_INTENT = 'payment_intent_incompatible_payment_method',
  BALANCE_INSUFFICIENT = 'balance-insufficient',
  BANK_ACCOUNT_BAD_ROUTING_NUMBERS = 'bank-account-bad-routing-numbers',
  BANK_ACCOUNT_DECLINED = 'bank-account-declined',
  BANK_ACCOUNT_RESTRICTED = 'bank-account-restricted',
  BANK_ACCOUNT_UNUSABLE = 'bank-account-unusable',
  BANK_ACCOUNT_UNVERIFIED = 'bank-account-unverified',
  CARD_DECLINE_RATE_LIMIT_EXCEEDED = 'card-decline-rate-limit-exceeded',
  CHARGE_EXPIRED_FOR_CAPTURE = 'charge-expired-for-capture',
  DEBIT_NOT_AUTHORIZED = 'debit-not-authorized',
  INSUFFICIENT_FUNDS = 'insufficient-funds',
  INTENT_INVALID_STATE = 'intent-invalid-state',
  INTENT_VERIFICATION_METHOD_MISSING = 'intent-verification-method-missing',
  INVALID_CARD_TYPE = 'invalid-card-type',
  PAYMENT_METHOD_BANK_ACCOUNT_BLOCKED = 'payment-method-bank-account-blocked',
  PAYMENT_METHOD_CUSTOMER_DECLINE = 'payment-method-customer-decline',
  PAYMENT_METHOD_PROVIDER_DECLINE = 'payment-method-provider-decline',
  PAYMENT_METHOD_PROVIDER_TIMEOUT = 'payment-method-provider-timeout',
  PAYMENT_METHOD_UNEXPECTED_STATE = 'payment-method-unexpected-state',
  PAYMENT_METHOD_UNSUPPORTED_TYPE = 'payment-method-unsupported-type',
  SEPA_UNSUPPORTED_ACCOUNT = 'sepa-unsupported-account',
  SETUP_INTENT_UNEXPECTED_STATE = 'setup-intent-unexpected-state',
}
