import React, { createContext, useEffect, useState } from 'react';

import { useUserIdentifierDealRelatedPages } from '~/hooks/useUserIdentifier';
import {
  CartInfo,
  CheckoutVisualStep,
  ConfirmationInfo,
  ContactHubspotInfo,
  ContactInfo,
  FinancialInfo,
  PaymentInfo,
  ProlongationInfo,
  ThankYouInfo,
} from '~/types/checkout';
import { CheckoutPageData } from '~/types/cosmicModules';
import { ReferralVoucherData } from '~/types/voucher';

import { GenericVehicleDetails } from './Vehicle';

type Checkout = {
  vehicle: GenericVehicleDetails;
  cartInfo: Partial<CartInfo>;
  setCartInfo: (value: Partial<CartInfo>) => void;
  contactInfo: Partial<ContactInfo>;
  paymentInfo: Partial<PaymentInfo>;
  thankYouInfo: Partial<ThankYouInfo>;
  confirmationInfo: Partial<ConfirmationInfo>;
  financialInfo: Partial<FinancialInfo>;
  pageData: CheckoutPageData;
  contactHubspotInfo: Partial<ContactHubspotInfo>;
  prolongationInfo: ProlongationInfo;
  dateMappings: Record<string, any>;
  customerTypeInForm?: string;
  setCustomerTypeInForm?: React.Dispatch<React.SetStateAction<string>>;
  loggedIn?: boolean;
  isLoadingRestore?: boolean;
  isLoading?: boolean;
  isCart?: boolean;
  isContact?: boolean;
  isPayment?: boolean;
  isConfirmation?: boolean;
  isProlongation?: boolean;
  contactExpanded: boolean;
  addressExpanded: boolean;
  deliveryExpanded: boolean;
  paymentExpanded: boolean;
  confirmationExpanded: boolean;
  reRenderKey?: number;
  setReRenderKey?: React.Dispatch<React.SetStateAction<number>>;
  expandedSteps: CheckoutVisualStep[];
  setExpandedSteps: React.Dispatch<React.SetStateAction<CheckoutVisualStep[]>>;
  referralVoucherData?: ReferralVoucherData;
  shouldAutoScroll: boolean;
  setShouldAutoScroll: React.Dispatch<React.SetStateAction<boolean>>;
  clientSecret?: string;
  setClientSecret: React.Dispatch<React.SetStateAction<string | undefined>>;
  stripeErrorMessage: string;
  setStripeErrorMessage: React.Dispatch<React.SetStateAction<string>>;
};

const CheckoutContext = createContext<Checkout>({} as Checkout);

type CheckoutProps = {
  vehicle?: GenericVehicleDetails;
  cartInfo?: CartInfo;
  restoredCartInfo?: CartInfo;
  contactInfo?: ContactInfo;
  paymentInfo?: PaymentInfo;
  thankYouInfo?: ThankYouInfo;
  confirmationInfo?: ConfirmationInfo;
  pageData?: CheckoutPageData;
  financialInfo?: FinancialInfo;
  prolongationInfo?: ProlongationInfo;
  contactHubspotInfo?: ContactHubspotInfo;
  dateMappings?: Record<string, any>;
  loggedIn?: boolean;
  isLoadingRestore?: boolean;
  isLoading?: boolean;
  isCart?: boolean;
  isContact?: boolean;
  isPayment?: boolean;
  isConfirmation?: boolean;
  isProlongation?: boolean;
  reRenderKey?: number;
  setReRenderKey?: React.Dispatch<React.SetStateAction<number>>;
  initialExpandedSteps?: CheckoutVisualStep[];
  referralVoucherData?: ReferralVoucherData;
};

export const CheckoutProvider: React.FunctionComponent<CheckoutProps> = ({
  children,
  vehicle = {},
  cartInfo = {},
  restoredCartInfo,
  contactInfo = {},
  paymentInfo = {},
  thankYouInfo = {},
  confirmationInfo = {},
  prolongationInfo = {},
  pageData = {},
  financialInfo = {},
  contactHubspotInfo = {},
  dateMappings = {},
  loggedIn,
  isLoadingRestore,
  isLoading,
  isCart,
  isContact,
  isPayment,
  isConfirmation,
  isProlongation,
  reRenderKey,
  setReRenderKey,
  referralVoucherData,
  initialExpandedSteps = [
    CheckoutVisualStep.CONTACT,
    CheckoutVisualStep.ADDRESS,
  ],
}) => {
  useUserIdentifierDealRelatedPages(
    cartInfo as CartInfo,
    contactInfo as ContactInfo
  );
  const [stripeErrorMessage, setStripeErrorMessage] = useState<string>();
  const [customerTypeInForm, setCustomerTypeInForm] = useState<string>();
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const [expandedSteps, setExpandedSteps] =
    useState<CheckoutVisualStep[]>(initialExpandedSteps);
  const [currCartInfo, setCartInfo] = useState(cartInfo);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

  // for unified checkout, cart info set in cart persists as it's passed to useState above
  // this causes problem with payment in payment and confirmation pages as restored cart info has more necessary data
  // we set cart info to restored cart info if it exists and when it's updated
  useEffect(() => {
    if (restoredCartInfo) {
      setCartInfo(restoredCartInfo);
    }
  }, [restoredCartInfo]);

  useEffect(() => {
    setCustomerTypeInForm('');
  }, [reRenderKey]);

  const contactExpanded = expandedSteps.includes(CheckoutVisualStep.CONTACT);
  const addressExpanded = expandedSteps.includes(CheckoutVisualStep.ADDRESS);
  const deliveryExpanded = expandedSteps.includes(CheckoutVisualStep.DELIVERY);
  const paymentExpanded = expandedSteps.includes(CheckoutVisualStep.PAYMENT);
  const confirmationExpanded = expandedSteps.includes(
    CheckoutVisualStep.CONFIRMATION
  );

  return (
    <CheckoutContext.Provider
      value={{
        vehicle: vehicle as GenericVehicleDetails,
        cartInfo: currCartInfo,
        setCartInfo,
        contactInfo,
        paymentInfo,
        thankYouInfo,
        confirmationInfo,
        financialInfo,
        pageData,
        contactHubspotInfo,
        dateMappings,
        prolongationInfo,
        customerTypeInForm,
        setCustomerTypeInForm,
        loggedIn,
        isLoadingRestore,
        isLoading,
        isCart,
        isContact,
        isPayment,
        isConfirmation,
        isProlongation,
        contactExpanded,
        addressExpanded,
        deliveryExpanded,
        paymentExpanded,
        confirmationExpanded,
        reRenderKey,
        setReRenderKey,
        expandedSteps,
        setExpandedSteps,
        referralVoucherData,
        shouldAutoScroll,
        setShouldAutoScroll,
        clientSecret,
        setClientSecret,
        stripeErrorMessage,
        setStripeErrorMessage,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
export default CheckoutContext;
