export const PROGRESS_BAR_WAIT_TIME = 500; // ms
export const RESTORE_DEDUPING_INTERVAL = 300000; // ms = 5 minutes
export const PREVIOUS_DEAL_DEDUPING_INTERVAL = 600000; // ms = 10 minutes
export const SESSION_STORAGE_CACHE_KEY = 'restore-cache-key';
export const checkoutPaths = {
  contact: '/checkout/contact',
  payment: '/checkout/payment',
  confirmation: '/checkout/confirmation',
  thankYou: '/checkout/thank_you',
  creditScore: '/checkout/credit-score',
  prolongation: '/checkout/prolongation',
};
