export { isServer } from '@finn/ui-utils';

export { mobileViewDetection } from '@finn/ui-utils';

export const copyToClipboard = (content: string) => {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(content);
  }
  const textArea = document.createElement('textarea');
  textArea.style.maxHeight = '0';
  textArea.style.height = '0';
  textArea.style.opacity = '0';
  textArea.value = content;
  document.body.appendChild(textArea);
  textArea.select();

  document.execCommand('copy');
  document.body.removeChild(textArea);
};

export const getFinnRequestId = (): string => {
  const requestId =
    new Date().getTime().toString(36) + Math.random().toString(36).substr(2, 9);

  return requestId;
};

export const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;
