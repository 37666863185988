import { traceabilityHeadersBrowser } from '@finn/ui-utils';

import { CheckoutRequestOptions, restoreProcess } from '~/services/checkout';
import { DealInfo } from '~/types/checkout';

export const restoreProcessFetcher = async ({
  dealInfo,
  checkoutRequestOptions,
  locale,
  isE2ETest = false,
}: {
  dealInfo: DealInfo | {};
  checkoutRequestOptions: CheckoutRequestOptions;
  locale: string;
  isE2ETest: boolean;
}) => {
  return await restoreProcess(
    dealInfo,
    checkoutRequestOptions,
    locale,
    isE2ETest,
    traceabilityHeadersBrowser()
  );
};
