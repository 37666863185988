import { isServer } from '@finn/ui-utils';

import { SESSION_STORAGE_CACHE_KEY } from '~/modules/checkout/constants';

export const getRestoreCacheValue = () => {
  if (isServer()) return;

  return sessionStorage.getItem(SESSION_STORAGE_CACHE_KEY) || 0;
};

export const incrementRestoreCacheValue = () => {
  if (isServer()) return;
  const newRestoreCacheValue = Number(getRestoreCacheValue()) + 1;
  sessionStorage.setItem(
    SESSION_STORAGE_CACHE_KEY,
    newRestoreCacheValue.toString()
  );
};
